<template>
  <NuxtLink :to="localePath(`/user-posts/${props.userPlacement.user.id}/predictions`)">
    <div class="flex items-center justify-between px-2 cursor-pointer">
      <div class="flex items-center gap-4">
        <div class="flex items-center">
          <!-- <ClientOnly v-if="props.userPlacement.status == 'up'">
            <ion-icon class="text-primary_500" name="trending-up"/>
          </ClientOnly>
          <ClientOnly v-else-if="props.userPlacement.status == 'down'">
            <ion-icon class="text-error_400" name="trending-down"/>
          </ClientOnly>
          <ClientOnly v-else>
            <ion-icon class="text-base_white" name="remove-outline"/>
          </ClientOnly> -->
          <p
            class="w-[32px] flex items-center justify-center text-[12px] font-medium text-neutral_50"
          >
            {{ props.userPlacement.position }}.
          </p>
        </div>
        <UserPicture
          class="pointer-events-none"
          gap="gap-2"
          color="text-neutral_50"
          :user="props.userPlacement.user"
        />
      </div>
      <p class="w-[40px] flex items-center justify-center text-[12px] font-medium text-neutral_50">
        {{ props.userPlacement.total_points }}
      </p>
    </div>
  </NuxtLink>
</template>
<script lang="ts" setup>
import type { PropType } from 'vue';
import type { UserPlacement } from '~/interfaces';

const localePath = useLocalePath();

const props = defineProps({
  userPlacement: {
    type: Object as PropType<UserPlacement>,
    required: true
  }
});
</script>
