<template>
  <div class="p-4 bg-neutral_700 rounded-[12px] flex flex-col gap-2">
    <InputText
      v-model="userToLookFor"
      class="sm:max-w-[296px]"
      size="small"
      :placeholder-text="`${$t('findCompetitor')}`"
      icon="search"
      @input="searchForUsersPlacement"
    />
    <TabComponent :tabs="tabs" />
    <div v-for="(placement, index) in placementsToDisplay" :key="index" class="flex flex-col gap-2">
      <UserPlacement :user-placement="placement" />
      <div v-if="index != placementsToDisplay.length - 1" class="h-px w-full bg-primary_800" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { tabItem, UserPlacement } from '~/interfaces';
import { challengeRepository } from '~/repositories/challengeRepository';

const { t } = useI18n();
const { $api } = useNuxtApp();
const challengeRepo = challengeRepository($api);
const userToLookFor = ref('');
const currentPlacement = ref('weekly');
const { debounce } = useGlobalFunctions();
const placementsToDisplay = ref<UserPlacement[]>([]);
const weeklyPlacements = ref<UserPlacement[]>([]);
const monthlyPlacements = ref<UserPlacement[]>([]);
const tabs = computed(
  () =>
    [
      {
        text: `${t('weeklyPlacement')}`,
        active: currentPlacement.value == 'weekly',
        action: async () => {
          await getWeeklyPlacements();
          userToLookFor.value = '';
          currentPlacement.value = 'weekly';
        }
      },
      {
        text: `${t('totalPlacement')}`,
        active: currentPlacement.value == 'total',
        action: async () => {
          await getMonthlyPlacements();
          userToLookFor.value = '';
          currentPlacement.value = 'total';
        }
      }
    ] as tabItem[]
);
onMounted(async () => {
  await getWeeklyPlacements();
});
const getWeeklyPlacements = async () => {
  if (weeklyPlacements.value.length) {
    placementsToDisplay.value = weeklyPlacements.value;
    return;
  }
  const response = await challengeRepo.getWeeklyLeaderboard();
  weeklyPlacements.value = response;
  placementsToDisplay.value = weeklyPlacements.value;
};
const getMonthlyPlacements = async () => {
  if (monthlyPlacements.value.length) {
    placementsToDisplay.value = monthlyPlacements.value;
    return;
  }
  const response = await challengeRepo.getMonthlyLeaderboard();
  monthlyPlacements.value = response;
  placementsToDisplay.value = monthlyPlacements.value;
};
const searchForUsersPlacement = debounce(async () => {
  //drugi uslov je da bi resetovao ako je prazan string
  if (userToLookFor.value.length >= 3 || userToLookFor.value.length == 0) {
    if (currentPlacement.value == 'weekly') {
      placementsToDisplay.value = await challengeRepo.getWeeklyLeaderboard(userToLookFor.value);
    } else {
      placementsToDisplay.value = await challengeRepo.getMonthlyLeaderboard(userToLookFor.value);
    }
  }
}, 400);
</script>
